import { Controller } from '@hotwired/stimulus';

import Uppy from '@uppy/core';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import XHRUpload from '@uppy/xhr-upload';
import DragDrop from '@uppy/drag-drop';

export default class extends Controller {
  static targets = ['hiddenInput', 'preview', 'form', 'dragDrop', 'template', 'field', 'statusBar', 'submitButton'];
  static values = {
    wrapperSelector: {
      default: '.nested-form-wrapper',
    },
  };

  allowedFileTypes() {
    const isPdf = this.dragDropTarget.dataset.pdf == 'true';
    const isText = this.dragDropTarget.dataset.text == 'true';
    const all = this.dragDropTarget.dataset.all == 'true';
    const allowedFileTypes = [];

    if (all) {
      allowedFileTypes.push('*/*');
    } else if (isPdf) {
      allowedFileTypes.push('application/pdf');
    } else if (isText) {
      allowedFileTypes.push('*/*');
    } else {
      allowedFileTypes.push('image/png');
      allowedFileTypes.push('image/jpeg');
      allowedFileTypes.push('image/jpg');
      allowedFileTypes.push('image/webp');
      allowedFileTypes.push('video/mp4');
      allowedFileTypes.push('audio/mp4');
    }

    return allowedFileTypes;
  }

  maxNumberOfFiles() {
    return this.dragDropTarget.dataset.maxamountfiles;
  }

  connect() {
    this.templates = new Map();
    this.fileCount = 0;

    this.uppy = new Uppy({
      autoProceed: true,
      restrictions: {
        allowedFileTypes: this.allowedFileTypes(),
        maxNumberOfFiles: this.maxNumberOfFiles(),
      },
      debug: true,
      locale: {
        strings: {
          browse: 'Click',
          dropHereOr: '%{browse} to upload or drag and drop',
        },
      },
    })
      .use(ThumbnailGenerator, {
        thumbnailHeight: 600,
      })
      .use(XHRUpload, {
        endpoint: '/upload',
      })
      .use(DragDrop, {
        target: this.dragDropTarget,
        note: this.dragDropTarget.dataset.note,
      });

    this.uppy.on('thumbnail:generated', (file, preview) => {
      const fileSize = Math.round(file.size / 1000) + ' KB';
      this.updatePreview(preview, file.name, fileSize);
    });

    this.uppy.on('upload-success', (file, response) => {
      const uploadedFileData = response.body.data;
      document.getElementById('status-bar').classList.add('hidden');
      this.updateData(file, JSON.stringify(uploadedFileData));
      this.enableButton();

      let uploadCounter = document.getElementById('upload_count');
      if (uploadCounter) {
        let uploadCount = parseInt(uploadCounter.innerHTML) + 1
        uploadCounter.innerHTML = uploadCount;
        if (uploadCount > 2) {
          this.dragDropTarget.classList.remove('!flex');
          this.dragDropTarget.classList.add('!hidden');
        }
      }

      this.updateFileCount(true)
    });

    this.uppy.on('upload', (_data) => {
      document.getElementById('status-bar').classList.remove('hidden');
      document.getElementById('status-bar-percentage').style.width = '0%';
    });

    this.uppy.on('upload-progress', (file, progress) => {
      let percentage = Math.round((progress.bytesUploaded / progress.bytesTotal) * 100);
      let fileSize = Math.round(file.size / 1000);
      document.getElementById('status-bar-percentage').style.width = percentage + '%';
      document.getElementById('status-bar-percentage-text').innerHTML = percentage + '%';
      document.getElementById('status-bar-file-name').innerHTML = file.name;
      document.getElementById('status-bar-file-size').innerHTML = fileSize + ' KB';
    });
  }

  updatePreview(preview, fileName, fileSize) {
    const template = this.findOrCreateTemplate(false, fileName);

    if (preview) {
      template.content.getElementsByClassName('image-data')[0].src = preview;
    }

    template.content.getElementsByClassName('file-name')[0].innerHTML = fileName;
    template.content.getElementsByClassName('file-size')[0].innerHTML = fileSize;
  }

  updateData(file, fileData) {
    const fileSize = Math.round(file.size / 1000) + ' KB';
    const template = this.findOrCreateTemplate(true, file.name);
    template.content.getElementsByClassName('upload-data')[0].value = fileData;
    template.content.getElementsByClassName('file-name')[0].innerHTML = file.name;
    template.content.getElementsByClassName('file-size')[0].innerHTML = fileSize;
  }

  findOrCreateTemplate(createCompletedTemplate, fileName) {
    let template;
    if (this.templates.has(fileName)) {
      template = this.templates.get(fileName);
      template.complete = true;
    } else {
      template = this.newTemplate(createCompletedTemplate, fileName);
    }

    return template;
  }

  newTemplate(createCompletedTemplate, fileName) {
    if (this.maxNumberOfFiles() == 1 && this.templateTarget.firstChild) {
      this.templateTarget.classList.remove('hidden');

      let template = { content: this.templateTarget, complete: false };
      this.templates.set(fileName, template)
      return template;
    }

    const content = new DOMParser().parseFromString(
      this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString()),
      'text/html',
    ).body.firstChild;

    content.setAttribute('data-file-name', fileName);

    this.fieldTarget.appendChild(content);

    let template = { content, complete: createCompletedTemplate };
    this.templates.set(fileName, template)
    return template;
  }

  remove(e) {
    const wrapper = e.target.closest(this.wrapperSelectorValue);
    const fileName = wrapper ? wrapper.getAttribute('data-file-name') : null;

    const uppyFile = this.uppy.getFiles().find(file => file.name === fileName);
    if (uppyFile) {
      this.uppy.removeFile(uppyFile.id);
    }
    this.templates.delete(fileName);

    this.enableButton();

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove();
    } else {
      wrapper.style.display = 'none';
      const input = wrapper.querySelector("input[name*='_destroy']");
      input.value = '1';
    }

    let uploadCounter = document.getElementById('upload_count');
    if (uploadCounter) {
      let uploadCount = parseInt(uploadCounter.innerHTML) - 1
      uploadCounter.innerHTML = uploadCount;
      if (uploadCount < 3 && this.dragDropTarget.classList.contains('!hidden')) {
        this.dragDropTarget.classList.remove('!hidden');
        this.dragDropTarget.classList.add('!flex');
      }
    }
    this.updateFileCount(false)
  }

  enableButton() {
    if (!this.hasSubmitButtonTarget) return;

    this.submitButtonTarget.disabled = false;
  }

  updateFileCount(increase) {
    this.fileCount += increase ? 1 : -1;
    const maxFiles = this.maxNumberOfFiles();
    const shouldHideDragDropTarget = this.fileCount >= maxFiles;
    this.toggleDragDropTarget(shouldHideDragDropTarget);
  }

  toggleDragDropTarget(hide) {
    if (hide) {
      this.dragDropTarget.classList.add('!hidden');
      this.dragDropTarget.classList.remove('!flex');
    } else {
      this.dragDropTarget.classList.remove('!hidden');
      this.dragDropTarget.classList.add('!flex');
    }
  }
}
