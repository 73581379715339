import { Popover } from 'tailwindcss-stimulus-components';

export default class extends Popover {
  show(event) {
    if (!this.isBottomSheet()) {
      const buttonRect = event.target.getBoundingClientRect();
      const popoverRect = this.measurePopover();

      const desiredHeight = buttonRect.top + popoverRect.height;
      const availableVerticalSpace = window.innerHeight;
      const amountToMove = (desiredHeight - availableVerticalSpace);

      if (!this.openValue) {
        if (amountToMove > 0) {
          const top = `${-amountToMove}px`;
          this.contentTarget.style.top = top;
        } else {
          this.contentTarget.style.top = '';
        }
      }
    }
    super.show();
  }

  measurePopover() {
    this.contentTarget.style.visibility = 'hidden';
    this.contentTarget.classList.toggle('hidden');

    const popoverRect = this.contentTarget.getBoundingClientRect();

    this.contentTarget.classList.toggle('hidden');
    this.contentTarget.style.visibility = 'visible';

    return popoverRect;
  }

  isBottomSheet() {
    const bottomSheets = this.contentTarget.querySelectorAll('[data-bottom-sheet]');
    return (bottomSheets.length != 1 && window.innerWidth < 768);
  }
}
