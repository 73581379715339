import CheckboxSelectAll from "@stimulus-components/checkbox-select-all"
import { get } from "@rails/request.js"

import { visit } from "@hotwired/turbo";

export default class extends CheckboxSelectAll {
  static targets = ["bulkActionBar", "downloadCounter", "creativeCounter", "emailCounter", "emailToPrinterCounter", "confirmCounter"]

  refresh(event) {
    this.updateCounters()
    this.toggleBulkActionBarVisibility()
  }

  toggleBulkActionBarVisibility() {
    this.checked.length > 0 ? this.showBulkActionBar() : this.hideBulkActionBar()
  }

  showBulkActionBar() {
    this.bulkActionBarTarget.classList.remove("!hidden")
    if (!this.isMobile()) return

    this.bulkActionBarTarget.classList.add('translate-y-full', 'transition-transform', 'transform', 'ease-in-out', 'duration-300');

    setTimeout(() => {
      this.bulkActionBarTarget.classList.remove('translate-y-full')
      this.bulkActionBarTarget.classList.add('translate-y-0');
    }, 0);
  }

  hideBulkActionBar() {
    if (this.isMobile()) {
      this.bulkActionBarTarget.classList.add('transition-transform', 'transform', 'ease-in-out', 'duration-300');

      setTimeout(() => {
        this.bulkActionBarTarget.classList.add('translate-y-full')
        this.bulkActionBarTarget.classList.remove('translate-y-0')
      }, 0);

      setTimeout(() => {
        this.bulkActionBarTarget.classList.add("!hidden")
      }, 100)
    } else {
      this.bulkActionBarTarget.classList.add("!hidden")
    }

  }

  updateCounters() {
    const counterMappings = [
      { target: this.downloadCounterTarget, type: "bulk_download" },
      { target: this.creativeCounterTarget, type: "bulk_proof_of_use" },
      { target: this.emailCounterTarget, type: "bulk_email" },
      { target: this.emailToPrinterCounterTarget, type: "bulk_email_to_printer" },
      { target: this.confirmCounterTarget, type: "Tasks::SchoolConfirmFulfillment" },
    ];


    counterMappings.forEach(mapping => {
      mapping.target.textContent = this.getCheckedOfTypeAmount(mapping.type);
    })
  }

  getCheckedOfTypeAmount(type) {
    return this.getCheckedOfType(type).length
  }

  getCheckedOfType(type) {
    const withDuplicates = this.checked.filter((checkbox) => checkbox.dataset.type?.includes(type)).map((checkbox) => parseInt(checkbox.value))
    const withoutDuplicates = [... new Set(withDuplicates)]
    return withoutDuplicates
  }

  checkboxTargetConnected(target) {
    const parentCheckbox = target.parentElement.closest('.table').querySelectorAll('[data-package-checkbox]')[0]

    if (!parentCheckbox) return

    target.checked = parentCheckbox.checked
    this.refresh()
  }

  checkNested() {
    if (event) {
      const parentElement = event.target.closest(".table")
      if (!parentElement) return

      const checkboxes = parentElement.querySelectorAll('input[data-bulk-target="checkbox"]')
      if (!checkboxes) return

      checkboxes.forEach((checkbox) => {
        checkbox.checked = event.target.checked
      })

      if (event.target.checked) this.togglePackageVisibility(event.target, false)
      this.refresh()
    }
  }

  unselectAll(event) {
    event.preventDefault()

    this.uncheckAll()
  }

  uncheckAll() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false

      this.triggerInputEvent(checkbox)
    })
  }

  toggleCollapse(event) {
    const parentlink = event.target.closest("a")
    if (parentlink) return

    const parentCheckbox = event.target.closest("input[type=\"checkbox\"]")
    if (parentCheckbox) return

    this.togglePackageVisibility(event.target, true)
  }

  togglePackageVisibility(packageElem, toggle) {
    const parentElement = packageElem.closest(".table")
    if (!parentElement) return

    const turboFrames = parentElement.querySelectorAll('turbo-frame[data-bulk-target="purchasedAssets"]')
    const accordionButtons = parentElement.querySelectorAll('div[data-bulk-target="accordionButton"]')

    const toggleClass = (elements, className, toggle) => {
      elements.forEach((element) => {
        if (toggle) {
          element.classList.toggle(className)
        } else {
          element.classList.remove(className)
        }
      })
    }

    toggleClass(turboFrames, "hidden", toggle)
    toggleClass(accordionButtons, "rotate-180", toggle)
  }

  async bulkDownload() {
    const checked = this.getCheckedOfType("bulk_download")

    if (checked.length === 0) return

    const response = await get("/schools/tasks/bulk_download", {
      query: { ids: JSON.stringify(checked) }
    })

    const blob = await response.response.blob()

    this.downloadFile(blob, "creatives.zip")
  }

  downloadFile(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;

    a.click();

    URL.revokeObjectURL(url);
  }

  bulk_proof_of_use() {
    if (this.isMobile()) {
      window.scrollTo(0, 0);
    }

    const checked = this.getCheckedOfType("bulk_proof_of_use")

    if (checked.length === 0) return

    const url = `/schools/tasks/${checked[0]}/bulk_proof_of_use_edit?ids=${encodeURIComponent(JSON.stringify(checked))}`
    document.getElementById("remote_modal").src = url
  }

  bulk_email() {
    if (this.isMobile()) {
      window.scrollTo(0, 0)
    }
    const checked = this.getCheckedOfType("bulk_email")

    if (checked.length === 0) return

    const url = `/schools/tasks/bulk_email_modal?ids=${encodeURIComponent(JSON.stringify(checked))}`
    document.getElementById("remote_modal").src = url
  }

  bulk_email_to_printer() {
    if (this.isMobile()) {
      window.scrollTo(0, 0)
    }
    const checked = this.getCheckedOfType("bulk_email_to_printer")

    if (checked.length === 0) return

    const url = `/schools/tasks/bulk_email_to_printer_modal?ids=${encodeURIComponent(JSON.stringify(checked))}`
    document.getElementById("remote_modal").src = url
  }

  isMobile() {
    return (window.innerWidth < 768)
  }
}
