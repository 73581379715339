import Alpine from 'alpinejs';

function firstLineRequirements() {
  return {
    firstLineRequired: false,
    setFirstLineRequired() {
      this.firstLineRequired = true;
    },
    setFirstLineNotRequired() {
      this.firstLineRequired = false;
    }
  }
}

Alpine.data('firstLineRequirements', firstLineRequirements);
